import { Component, createRef } from 'react';
import { Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  ICustomFormStatePersistenceService,
  ManualTaskAbortedEvent,
  ManualTaskFinishedEvent,
  ManualTaskSuspendedEvent,
  UserTaskAbortedEvent,
  UserTaskFinishedEvent,
  UserTaskSuspendedEvent,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { UserInteractionComponent } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';

import { BpmnType, Identity } from '@atlas-engine/atlas_engine_sdk';

import {
  AnyTaskType,
  AtlasEngineService,
  IAuthService,
  TaskViewConfig,
} from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { BackToHomepage } from '../../components/BackToHomepage';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

type TaskViewState = {
  identity?: Identity | null;
  task?: AnyTaskType | null;
  taskIsFinished: boolean;
  isLoading: boolean;
  error?: Error;
};

type TaskViewProps = RouteComponentProps & {
  atlasEngineService: AtlasEngineService;
  taskViewConfig: TaskViewConfig;
  authService: IAuthService;
  correlationId: string;
  processInstanceId: string;
  flowNodeInstanceId: string;
  componentStatePersistenceService: ICustomFormStatePersistenceService;
} & WithTranslation & GenericViewProps;

class TaskViewComponent extends Component<TaskViewProps, TaskViewState> {

  public state: TaskViewState = {
    identity: null,
    task: null,
    taskIsFinished: false,
    isLoading: true,
  }

  private userInteractionComponent = createRef<UserInteractionComponent>();

  public componentDidMount(): void {
    this.loadData();
  }

  public componentDidUpdate(): void {
    if (!this.state.isLoading && !this.state.taskIsFinished) {
      this.handleUserInteractionComponentMount();
    }
  }

  public async componentWillUnmount(): Promise<void> {

    if (
      this.state.task &&
      this.state.task.flowNodeType === BpmnType.userTask &&
      !this.state.taskIsFinished
    ) {
      await this.props.atlasEngineService.cancelUserTaskReservation(this.state.task.flowNodeInstanceId);
    }

    this.handleUserInteractionComponentUnmount();
  }

  public render(): JSX.Element {
    if (this.state.isLoading) {
      return <div>{this.props.t('TaskView.Loading')}</div>;
    }

    if (this.state.error) {
      return <ErrorRenderer error={this.state.error} />;
    }

    const taskWasNotFound = !this.state.identity || !this.state.task;
    if (taskWasNotFound) {
      return (
        <Alert variant='warning'>
          {this.props.t('TaskView.TaskNotFound')}
          <LinkContainer className='ml-1' to={`/correlation/${this.props.correlationId}`}>
            <Alert.Link>
              {this.props.t('TaskView.SwitchToOverview')}
            </Alert.Link>
          </LinkContainer>
        </Alert>
      );
    }

    return (
      <Layout>
        <LayoutSidebar visible={this.props.sidebarVisible} hideSidebar={this.props.hideSidebar} />
        <LayoutContent>

          <div className="task-view">
            <BackToHomepage />
            <div className="task-view__content">
              <user-interaction-component ref={this.userInteractionComponent} style={{ height: '100%' }}/>
            </div>
          </div>

        </LayoutContent>
      </Layout>
    );
  }

  private async loadData(): Promise<void> {
    try {
      await Promise.all([
        this.loadTask(),
        this.loadIdentity(),
      ]);
    } catch (error) {
      this.setState({ error: error });
    }

    this.setState({ isLoading: false });
  }

  private async loadTask(): Promise<void> {
    const task = await this.props.atlasEngineService.getTaskByFlowNodeInstanceId(this.props.flowNodeInstanceId);

    if (task?.flowNodeType === BpmnType.userTask) {
      await this.props.atlasEngineService.reserveUserTask(task.flowNodeInstanceId);
    }

    this.setState({ task: task ?? null });
  }

  private async loadIdentity(): Promise<void> {
    const identity = await this.props.authService.getIdentity();
    this.setState({ identity: identity });
  }

  private handleUserInteractionComponentMount(): void {
    const { task, identity } = this.state;
    const userInteractionComponent = this.userInteractionComponent.current;
    // check if task and identity are loaded, the task has a flowNodeInstance and if the
    // userInteractionComponent is mounted.
    // We do it this way here instead of moving these checks to variables, so that typescript
    // knows, that these variables are not undefined after the early-exit.
    if (!task || !identity || !task?.flowNodeInstanceId || !userInteractionComponent) {
      return;
    }

    userInteractionComponent.identity = identity;
    userInteractionComponent.customFormConfig = {};
    userInteractionComponent.atlasEngineBaseUrl = this.props.atlasEngineService.getAtlasEngineBaseUrl();
    userInteractionComponent.disableAutoWait = true;
    userInteractionComponent.customFormResolver = this.props.taskViewConfig.resolveCustomForm;
    userInteractionComponent.customFormStatePersistentService = this.props.componentStatePersistenceService;

    if (task.flowNodeType === BpmnType.userTask) {
      userInteractionComponent.displayUserTask(task.flowNodeInstanceId);
    } else if (task.flowNodeType === BpmnType.manualTask) {
      userInteractionComponent.displayManualTask(task.flowNodeInstanceId);
    } else {
      throw new Error('Unknown task type.');
    }

    userInteractionComponent.addEventListener(ManualTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirect.bind(this));
    userInteractionComponent.addEventListener(ManualTaskFinishedEvent.type, this.handleTaskFinished.bind(this));
    userInteractionComponent.addEventListener(ManualTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirect.bind(this));
    userInteractionComponent.addEventListener(UserTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirect.bind(this));
    userInteractionComponent.addEventListener(UserTaskFinishedEvent.type, this.handleTaskFinished.bind(this));
    userInteractionComponent.addEventListener(UserTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirect.bind(this));
  }

  private handleUserInteractionComponentUnmount(): void {
    const userInteractionComponent = this.userInteractionComponent.current;
    if (!userInteractionComponent) {
      return;
    }

    userInteractionComponent.removeEventListener(ManualTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirect);
    userInteractionComponent.removeEventListener(ManualTaskFinishedEvent.type, this.handleTaskFinished);
    userInteractionComponent.removeEventListener(ManualTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirect);
    userInteractionComponent.removeEventListener(UserTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirect);
    userInteractionComponent.removeEventListener(UserTaskFinishedEvent.type, this.handleTaskFinished);
    userInteractionComponent.removeEventListener(UserTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirect);
  }

  private handleTaskFinished(): void {
    this.setState({ taskIsFinished: true });
    this.navigateToCorrelation();
  }

  private navigateToCorrelation(): void {
    this.props.history.push(`/correlation/${this.props.correlationId}`);
  }

  private navigateToCorrelationWithoutAutoRedirect(): void {
    this.props.history.push(`/correlation/${this.props.correlationId}?autoNavigate=false`);
  }

}

export const TaskView = withTranslation()(withRouter(TaskViewComponent));
