import {PropsWithChildren, useEffect, useState} from 'react';
import { Route } from 'react-router-dom';

import { IAuthService } from '../lib/index';
import { LoginPromptView } from './views/login-prompt-view/LoginPromptView';

export type PrivateRouteProps = {
  authService: IAuthService;
  path: string;
  exact: boolean | undefined;
};

export function PrivateRoute(props: PropsWithChildren<PrivateRouteProps>): JSX.Element | null {
  const {
    children,
    authService,
    ...rest
  } = props;

  const [
    restoreUserPending,
    setRestoreUserPending,
  ] = useState(true);

  useEffect(() => {
    props.authService.detectLoggedInFlag()
      .then(() => {
        setRestoreUserPending(false);
      });
  }, [props.authService]);

  if (restoreUserPending) {
    return null;
  }

  return (

    <Route
      {...rest}
      render={({ location }): any => {
        if (authService.isLoggedIn()) {
          return children;
        }

        return <LoginPromptView authService={authService} redirectAfterLogin={location} />;
      }}
    />
  );
}
