import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import { TranslatedApp } from './App';
import {
  AtlasAuthorityUnreachableError, AtlasEngineUnreachableError, ConfigurationService, PortalConfiguration,
} from '../lib/index';
import { ErrorRenderer } from './components/ErrorRenderer';
import {
  Layout, LayoutContent, LayoutHeader, LayoutSidebar,
} from './Layout';

type AppBootstrapperProps = WithTranslation;

export type AppBootstrapperState = {
  isLoading: boolean;
  appConfig: PortalConfiguration | null;
  error: Error | null;
  sidebarVisible: boolean;
  willReload: boolean;
};

class AppBootstrapper extends Component<AppBootstrapperProps, AppBootstrapperState> {

  private configurationService: ConfigurationService;

  constructor(props: AppBootstrapperProps) {
    super(props);

    this.configurationService = new ConfigurationService();

    this.state = {
      isLoading: true,
      appConfig: null,
      error: null,
      sidebarVisible: false,
      willReload: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    try {
      const appConfig = await this.configurationService.loadConfig();

      this.setState({
        isLoading: false,
        appConfig: appConfig,
      });

    } catch (error) {

      this.setState({
        isLoading: false,
        appConfig: null,
        error: error,
      });
    }
  }

  public componentDidUpdate(): void {
    const { error, willReload } = this.state;

    if (error && !willReload) {
      if (error instanceof AtlasAuthorityUnreachableError || error instanceof AtlasEngineUnreachableError) {
        this.reloadPageDeferred();
      }
    }
  }

  public render(): JSX.Element | null {
    const { t } = this.props;

    if (this.state.error) {
      return (
        // "BrowserRouter" is required because "LayoutSidebar" integrates a component which uses "withRouter".
        <BrowserRouter>
          <Layout>
            <LayoutSidebar hideSidebar={this.hideSidebar.bind(this)} visible={this.state.sidebarVisible} />
            <LayoutHeader title={t('ErrorTitle')} onMenuClick={this.toggleSidebar.bind(this)} showSearch={false} />
            <LayoutContent>
              <ErrorRenderer error={this.state.error} />
              <Alert variant="info">
                {t('PageWillRefreshAutomatically')}
              </Alert>
            </LayoutContent>
          </Layout>
        </BrowserRouter>
      );
    }

    if (this.state.isLoading || !this.state.appConfig) {
      return (
        // "BrowserRouter" is required because "LayoutSidebar" integrates a component which uses "withRouter".
        <BrowserRouter>
          <Layout>
            <LayoutSidebar hideSidebar={this.hideSidebar.bind(this)} visible={this.state.sidebarVisible} />
            <LayoutHeader title={t('ApplicationLoading')} onMenuClick={this.toggleSidebar.bind(this)} showSearch={false} />
          </Layout>
        </BrowserRouter>
      );
    }

    document.title = t('ApplicationTitle');

    return <TranslatedApp {...this.state.appConfig} />;
  }

  private toggleSidebar(): void {
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  }

  private hideSidebar(): void {
    this.setState({ sidebarVisible: false });
  }

  private reloadPageDeferred(): void {
    this.setState({ willReload: true });

    setTimeout(() => {
      window.location.reload();
    }, 30 * 1000);
  }

}

export const TranslatedAppBootstrapper = withTranslation()(AppBootstrapper);
