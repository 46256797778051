import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IAuthService } from '../../../lib';
import i18n from '../../../i18n';
import {Layout, LayoutContent, LayoutHeader, LayoutSidebar} from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

export type UserSettingsViewProps = {
  authService: IAuthService;
} & RouteComponentProps & WithTranslation & GenericViewProps;

function UserSettingsView(props: UserSettingsViewProps): JSX.Element {
  const { t } = props;

  const language = i18n.language.replace(/-.+$/, '');
  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  return (
    <Layout>
      <LayoutSidebar activeNav="user-settings" visible={props.sidebarVisible} hideSidebar={props.hideSidebar} />
      <LayoutHeader title={t('Header.TitleUserSettings')} showSearch={false} onMenuClick={props.onMenuClick} />
      <LayoutContent>

        <div className="user-settings-view">
          <div className="user-settings-view__content">

            <div className="box">
              <div className="box__title">
                {props.t('UserSettings.HeaderLanguages')}
              </div>
              <div className="box__body">
                <div className="btn-group">
                  <button className={`btn btn--default ${language === 'en' ? 'active' : ''}`} onClick={(): void => changeLanguage('en')}>English</button>
                  <button className={`btn btn--default ${language === 'de' ? 'active' : ''}`} onClick={(): void => changeLanguage('de')}>Deutsch</button>
                </div>
              </div>
            </div>

            <div className="box">
              <div className="box__title">
                {props.t('UserSettings.HeaderLogout')}
              </div>
              <div className="box__body">
                <button className="btn btn-outline-danger btn--logout" onClick={(): void => props.authService.logout()}>{props.t('UserSettings.Logout')}</button>
              </div>
            </div>

          </div>
        </div>

      </LayoutContent>
    </Layout>
  );

}

export const UserSettingsViewWithRouter = withTranslation()(withRouter(UserSettingsView));
