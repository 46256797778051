import { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AtlasEngineService, StartableGroupConfig } from '../../../lib';
import { ErrorRenderer } from '../ErrorRenderer';
import { ProcessModel } from './ProcessModel';
import { Startable } from './StartableList';
import { StartDialog } from './StartDialog';

type StartableGroupProps = {
  group: StartableGroupConfig;
  startables: Array<Startable>;
  atlasEngineService: AtlasEngineService;
  searchFilter?: string;
} & RouteComponentProps;

type StartableGroupState = {
  lastProcessStartError?: Error;
};

export class StartableGroupComponent extends Component<StartableGroupProps, StartableGroupState> {

  constructor(props: StartableGroupProps) {
    super(props);

    this.state = {};
  }

  public render(): JSX.Element {
    const { group, startables } = this.props;
    const safeGroupId = group.id.trim().replaceAll(' ', '-');
    const startableComponents = startables.map(this.mapStartableToComponent.bind(this));

    return (
      <Fragment>
        {this.state.lastProcessStartError && <ErrorRenderer error={this.state.lastProcessStartError}/>}
        <div className={`startable-group startable-group--${safeGroupId}`}>
          <div className="startable-group__title">{group.title}</div>
          <div className="startable-group__startables">
            {startableComponents}
          </div>
        </div>
      </Fragment>
    );
  }

  private mapStartableToComponent(startable: Startable): JSX.Element {
    switch (startable.type) {
      case 'processModel':
        return (
          <ProcessModel
            key={startable.config.id}
            id={startable.config.id}
            onStart={this.startProcessAndNavigateToProcess.bind(this)}
            startButtonTitles={startable.config.startButtonTitles}
            title={startable.config.title}
            body={startable.config.body}
            searchFilter={this.props.searchFilter}
          />
        );
      case 'startDialog':
        return (
          <StartDialog
            key={startable.config.id}
            body={startable.config.body}
            onStart={this.navigateToStartDialog.bind(this)}
            startButtonTitle={startable.config.startButtonTitle}
            startDialogId={startable.config.id}
            title={startable.config.title}
            url={startable.config.url}
            searchFilter={this.props.searchFilter}
          />
        );
      default:
        throw new Error('Unknown startable type encountered');
    }
  }

  private async startProcessAndNavigateToProcess(processModelId: string, startEventId?: string): Promise<void> {
    try {
      const processInstance = await this.props.atlasEngineService.startProcessInstance(processModelId, undefined, startEventId);
      this.props.history.push(`/correlation/${processInstance.correlationId}`);
    } catch (error) {
      this.setState({ lastProcessStartError: error });
    }
  }

  private navigateToStartDialog(startDialogId: string): void {
    this.props.history.push(`/startdialog/${startDialogId}`);
  }

}

export const StartableGroup = withRouter(StartableGroupComponent);
