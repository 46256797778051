import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LinkContainer } from 'react-router-bootstrap';

export type AppHeaderProps = {
  onMenuClick: () => void;
  onSearchChanged?: (value: string) => void;
  showSearch: boolean;
  title?: string;
}

export function AppHeader(props: AppHeaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="app-header">
      <div className="app-header__title">{props.title == null ? null : t(props.title)}</div>

      {props.showSearch &&
        <div className="app-header__search-bar">
          <div className="search-bar">
            <Form.Control
              type="text"
              className="search-bar__input"
              placeholder={t('Header.SearchPlaceholder')}
              onChange={(inputEvent): void => props.onSearchChanged && props.onSearchChanged(inputEvent.target?.value)}
            />

            <div className="search-bar__submit">
              <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" /></svg>
            </div>
          </div>
        </div>
      }

      <LinkContainer to="/user-settings">
        <div className="app-header__help-icon">
          <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" /></svg>
        </div>
      </LinkContainer>

      <div className="app-header__menu-icon" onClick={(): void => props.onMenuClick()}>
        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" /></svg>
      </div>
    </div>
  );
}
