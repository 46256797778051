import {
  Component, createRef, useEffect, useState,
} from 'react';
import { Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Identity } from '@atlas-engine/atlas_engine_sdk';
import {
  DisplayStartDialogMessage,
  StartDialogMessageType,
  isCloseStartDialogMessage,
  isStartProcessStartDialogMessage,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { Base64 } from 'js-base64';

import { IAuthService, StartDialogConfig, StartDialogsConfig } from '../../../lib';
import { GenericViewProps } from '../../GenericViewProps';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { BackToHomepage } from '../../components/BackToHomepage';

type StartDialogViewProps = {
  startDialogId: string;
  config: StartDialogConfig;
  navigate: (url: string) => void;
  identity: Identity;
} & GenericViewProps;

type StartDialogViewState = {
}

type StartDialogViewWithRouterProps = {
  authService: IAuthService;
  config: StartDialogsConfig;
} & GenericViewProps;

type StartDialogViewParameters = {
  startDialogId: string;
}

class StartDialogView extends Component<StartDialogViewProps, StartDialogViewState> {

  private iFrameRef = createRef<HTMLIFrameElement>();

  public componentDidMount(): void {
    const iframe = this.iFrameRef.current;
    if (iframe) {
      iframe.addEventListener('load', this.handleIFrameLoaded.bind(this));
    }

    window.addEventListener('message', this.handleMessageReceived.bind(this));
  }

  public componentWillUnmount(): void {
    const iframe = this.iFrameRef.current;
    if (iframe) {
      iframe.removeEventListener('load', this.handleIFrameLoaded.bind(this));
    }

    window.removeEventListener('message', this.handleMessageReceived.bind(this));
  }

  public render(): JSX.Element {
    return (
      <Layout>
        <LayoutSidebar visible={this.props.sidebarVisible} hideSidebar={this.props.hideSidebar} />
        <LayoutContent>

          <div className="start-dialog-view">
            <BackToHomepage />
            <div className="start-dialog-view__content">
              <iframe
                className="start-dialog-view__iframe"
                ref={this.iFrameRef}
                title={this.props.config.title}
                src={this.props.config.url}
                frameBorder='0'
              />
            </div>
          </div>

        </LayoutContent>
      </Layout>
    );
  }

  private handleIFrameLoaded(): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow) {
      return;
    }

    const message: DisplayStartDialogMessage = {
      type: StartDialogMessageType.DisplayStartDialog,
      configuration: this.props.config,
      identity: this.props.identity,
    };

    iFrame.contentWindow.postMessage(message, this.getIFrameOrigin());
  }

  private handleMessageReceived(event: MessageEvent): void {
    const message = event.data;

    if (isCloseStartDialogMessage(message)) {
      this.props.navigate('/');

    } else if (isStartProcessStartDialogMessage(message)) {
      const returnToStartDialogPayload = { returnToStartDialog: this.props.startDialogId };
      const payload = Object.assign(returnToStartDialogPayload, message.payload);
      const encodedPayload = Base64.encode(JSON.stringify(payload));

      this.props.navigate(`/start/${message.processModelId}/${encodedPayload}`);
    }
  }

  private getIFrameOrigin(): string {
    return new URL(this.props.config.url).origin;
  }

}

export function StartDialogViewWithRouter(props: StartDialogViewWithRouterProps): JSX.Element | null {
  const { t } = useTranslation();
  const { startDialogId } = useParams<StartDialogViewParameters>();
  const { push } = useHistory();

  const [identity, setIdentity] = useState<Identity | null>(null);

  const { authService } = props;
  useEffect(() => {
    authService.getIdentity()
      .then((newIdentity) => {
        setIdentity(newIdentity);
      });
  }, [
    authService, setIdentity,
  ]);

  const startDialogConfig = props.config[startDialogId];

  if (!startDialogId || !startDialogConfig) {
    return (
      <Alert variant="danger">
        <Trans
          i18nKey="StartDialogView.StartDialogNotFound"
          values={{
            startDialogId: startDialogId,
          }}
          components={{
            code: <code/>,
          }}
        ></Trans>
        <LinkContainer to="/">
          <Alert.Link className="ml-1">
            {t('StartDialogView.BackToHomepageLink')}
          </Alert.Link>
        </LinkContainer>
      </Alert>
    );
  }

  if (!identity) {
    return null;
  }

  return (
    <StartDialogView
      {...props}
      startDialogId={startDialogId}
      config={startDialogConfig}
      navigate={push}
      identity={identity}/>
  );
}
