import { createContext } from 'react';

import { AtlasEngineClient } from '@atlas-engine/atlas_engine_client';
import { BpmnType, DataModels, EventReceivedCallback, Messages, Subscription } from '@atlas-engine/atlas_engine_sdk';

import { IAuthService } from './IAuthService';
import { AnyTaskType, AtlasEngineUnreachableError, isConnectionError } from './InternalTypes';
import { PortalConfiguration, ProcessModelConfig } from './PortalConfiguration';

export const AtlasEngineContext = createContext<AtlasEngineService | undefined>(undefined);

export type OnCorrelationStateChangedCallback = (correlation: DataModels.Correlation.Correlation) => void;
export type OnCorrelationProgressCallback = (correlation: Messages.EventMessage) => void;

export class AtlasEngineService {
  private readonly authService: IAuthService;
  private readonly atlasEngineClient: AtlasEngineClient;

  private readonly config: PortalConfiguration;

  constructor(authService: IAuthService, config: PortalConfiguration) {
    this.authService = authService;
    this.config = config;
    this.atlasEngineClient = new AtlasEngineClient(config.engineUrl);
  }

  public getAtlasEngineBaseUrl(): string {
    return this.config.engineUrl;
  }

  public async getAtlasEngineVersion(): Promise<string> {
    try {
      const applicationInfo = await this.atlasEngineClient.applicationInfo.getApplicationInfo();

      return applicationInfo.version;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async startProcessInstance(
    processModelId: string,
    payload: Record<string, unknown> | undefined = undefined,
    startEventId: string | undefined = undefined,
  ): Promise<DataModels.ProcessInstances.ProcessStartResponse> {

    try {
      const result = await this.atlasEngineClient.processDefinitions.startProcessInstance(
        {
          processModelId: processModelId,
          initialToken: payload,
          startEventId: startEventId,
        },
        await this.authService.getIdentity(),

      );

      return result;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async reserveUserTask(flowNodeInstanceId: string): Promise<void> {
    try {
      const currentIdentity = await this.authService.getIdentity();

      await this.atlasEngineClient.userTasks.reserveUserTaskInstance(
        currentIdentity,
        flowNodeInstanceId,
        currentIdentity.userId,
      );
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async cancelUserTaskReservation(flowNodeInstanceId: string): Promise<void> {
    try {
      await this.atlasEngineClient.userTasks.cancelUserTaskInstanceReservation(
        await this.authService.getIdentity(),
        flowNodeInstanceId,
      );
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async getCorrelation(correlationId: string): Promise<DataModels.Correlation.Correlation> {
    try {
      const correlation = await this.atlasEngineClient
        .correlations
        .getById(correlationId, await this.authService.getIdentity(), true);

      return correlation;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async getProcessModels(): Promise<Array<ProcessModelConfig>> {
    try {
      const result = await this.atlasEngineClient.processDefinitions.getAll(await this.authService.getIdentity());

      const configuredProcessModelList: Array<ProcessModelConfig> = [];

      for (const processDefinition of result.processDefinitions) {

        const configuredProcessModels = processDefinition
          .processModels
          .filter((processModel) => {

            const isInIncludeList =
              this.config.processModels.include?.length === 0 ||
              this.config.processModels.include?.some((id) => id === processModel.processModelId);

            const isNotInExcludeList = !this.config.processModels.exclude?.some((id) => id === processModel.processModelId);

            return processModel.isExecutable && isInIncludeList && isNotInExcludeList;
          })
          .map((processModel) => this.configureProcessModel(processModel));

        configuredProcessModelList.push(...configuredProcessModels);
      }

      configuredProcessModelList.sort((processModelA, processModelB) => {
        const titleA = processModelA.title.toLowerCase();
        const titleB = processModelB.title.toLowerCase();

        return titleA.localeCompare(titleB);
      });

      return configuredProcessModelList;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async onCorrelationStateChanged(
    correlationIdToSubscribeTo: string,
    callback: OnCorrelationStateChangedCallback,
  ): Promise<Array<Subscription>> {
    try {

      const refreshCorrelationCallback = async (event: Messages.EventMessage): Promise<void> => {
        if (correlationIdToSubscribeTo === event.correlationId) {
          const correlation = await this.getCorrelation(correlationIdToSubscribeTo);
          callback(correlation);
        }
      };

      const newSubscriptions = await Promise.all([
        this.atlasEngineClient.notification.onProcessStarted(refreshCorrelationCallback.bind(this), false, await this.authService.getIdentity()),
        this.atlasEngineClient.notification.onProcessEnded(refreshCorrelationCallback.bind(this), false, await this.authService.getIdentity()),
        this.atlasEngineClient.notification.onProcessError(refreshCorrelationCallback.bind(this), false, await this.authService.getIdentity()),
        this.atlasEngineClient.notification.onProcessTerminated(refreshCorrelationCallback.bind(this), false, await this.authService.getIdentity()),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async onProcessInstanceStateChanged(callback: EventReceivedCallback): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.atlasEngineClient.notification.onProcessEnded(callback, false, identity),
        this.atlasEngineClient.notification.onProcessError(callback, false, identity),
        this.atlasEngineClient.notification.onProcessStarted(callback, false, identity),
        this.atlasEngineClient.notification.onProcessTerminated(callback, false, identity),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async onTaskStatesChanged(callback: EventReceivedCallback): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.atlasEngineClient.notification.onUserTaskFinished(callback, false, identity),
        this.atlasEngineClient.notification.onUserTaskWaiting(callback, false, identity),
        this.atlasEngineClient.notification.onUserTaskReserved(callback, false, identity),
        this.atlasEngineClient.notification.onUserTaskReservationCanceled(callback, false, identity),
        this.atlasEngineClient.notification.onManualTaskFinished(callback, false, identity),
        this.atlasEngineClient.notification.onManualTaskWaiting(callback, false, identity),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async onDeployedProcessesChanged(callback: Messages.CallbackTypes.OnProcessDeployedCallback): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.atlasEngineClient.notification.onProcessDeployed(callback, false, identity),
        this.atlasEngineClient.notification.onProcessUndeployed(callback, false, identity),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async onCorrelationProgress(
    correlationId: string,
    callback: OnCorrelationProgressCallback,
  ): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const correlationCheckCallback = (message: Messages.EventMessage): void => {
        if (message.correlationId === correlationId) {
          callback(message);
        }
      };

      const newSubscriptions = await Promise.all([
        this.atlasEngineClient.notification.onActivityReached(correlationCheckCallback, false, identity),
        this.atlasEngineClient.notification.onActivityFinished(correlationCheckCallback, false, identity),
        this.atlasEngineClient.notification.onIntermediateCatchEventReached(correlationCheckCallback, false, identity),
        this.atlasEngineClient.notification.onIntermediateCatchEventFinished(correlationCheckCallback, false, identity),
        this.atlasEngineClient.notification.onIntermediateThrowEventTriggered(correlationCheckCallback, false, identity),
        this.atlasEngineClient.notification.onUserTaskReserved(correlationCheckCallback, false, identity),
        this.atlasEngineClient.notification.onUserTaskReservationCanceled(correlationCheckCallback, false, identity),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  public async removeSubscriptions(subscriptions: Array<Subscription>): Promise<void> {
    const identity = await this.authService.getIdentity();
    await Promise.all(subscriptions.map((subscription) => this.atlasEngineClient.notification.removeSubscription(identity, subscription)));
  }

  public async getTasks(): Promise<Array<AnyTaskType>> {
    return this.queryUserTasksAndManualTasks({
      state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
    });
  }

  public async getTasksInCorrelation(correlationId: string): Promise<Array<AnyTaskType>> {
    return this.queryUserTasksAndManualTasks({
      correlationId: correlationId,
      state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
    });
  }

  public async getTaskByFlowNodeInstanceId(flowNodeInstanceId: string): Promise<AnyTaskType | undefined> {
    const tasks = await this.queryUserTasksAndManualTasks({
      flowNodeInstanceId: flowNodeInstanceId,
    });

    return tasks.length > 0 ? tasks.pop() : undefined;
  }

  public async getProcessInstance(
    correlationId: string,
    processInstanceId: string,
  ): Promise<DataModels.ProcessInstances.ProcessInstance | undefined> {
    try {
      const result = await this.atlasEngineClient.processInstances.query(
        {
          correlationId: correlationId,
          processInstanceId: processInstanceId,
        },
        await this.authService.getIdentity(),
      );

      return result.processInstances.length > 0 ? result.processInstances.pop() : undefined;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  private configureProcessModel(processModelFromApi: DataModels.ProcessDefinitions.ProcessModel): ProcessModelConfig {

    const processModelSettings = this.getProcessModelSettingsFromConfig(processModelFromApi.processModelId);

    const processModelConfig: ProcessModelConfig = {
      id: processModelFromApi.processModelId,
      title: processModelSettings?.title ?? processModelFromApi.processModelName ?? processModelFromApi.processModelId,
      body: processModelSettings?.body ?? '',
      startButtonTitles: {},
    };

    processModelFromApi.startEvents.forEach((startEvent) => {

      const startEventTitlefromConfig = processModelSettings?.startButtonTitles && Object.keys(processModelSettings.startButtonTitles).length > 0
        ? processModelSettings?.startButtonTitles[startEvent.id]
        : undefined;

      processModelConfig.startButtonTitles[startEvent.id] = startEventTitlefromConfig ?? startEvent.name ?? startEvent.id;
    });

    if (processModelSettings?.groupId) {
      processModelConfig.groupId = processModelSettings.groupId;
    }

    return processModelConfig;
  }

  private getProcessModelSettingsFromConfig(processModelId: string): ProcessModelConfig | undefined {

    if (!this.config.processModels.settings) {
      return undefined;
    }

    return this.config.processModels.settings[processModelId];
  }

  private async queryUserTasksAndManualTasks(query: DataModels.FlowNodeInstances.GenericFlowNodeInstanceQuery): Promise<Array<AnyTaskType>> {
    try {
      const results = await this.atlasEngineClient.flowNodeInstances.queryFlowNodeInstances(
        {
          ...query,
          flowNodeType: [BpmnType.manualTask, BpmnType.userTask],
        },
        await this.authService.getIdentity(),
      );

      return results.flowNodeInstances;
    } catch (error) {
      throw this.handleAtlasEngineRequestError(error);
    }
  }

  private handleAtlasEngineRequestError(error: any): void {
    if (isConnectionError(error)) {
      throw new AtlasEngineUnreachableError(error.code);
    }

    throw error;
  }
}
