import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IAuthService } from '../../../lib/index';
import { BackToHomepage } from '../../components/BackToHomepage';
import { ErrorRenderer } from '../../components/ErrorRenderer';

type LoginPromptViewProps = {
  authService: IAuthService;
  redirectAfterLogin: unknown;
}

export function LoginPromptView(props: LoginPromptViewProps): JSX.Element | null {
  const history = useHistory();
  const { t } = useTranslation();

  const [
    restoreUserPending,
    setRestoreUserPending,
  ] = useState(true);

  const [
    loginError,
    setLoginError,
  ] = useState<Error | null>(null);

  useEffect(() => {
    props.authService.detectLoggedInFlag()
      .then((isLoggedIn) => {
        if (!restoreUserPending) {
          return;
        }
        setRestoreUserPending(false);

        if (isLoggedIn) {
          history.replace('/');
        } else {
          doLogin();
        }
      });
  });

  const doLogin = async (): Promise<void> => {
    setLoginError(null);
    try {
      await props.authService.login(props.redirectAfterLogin);
    } catch (err) {
      setLoginError(err);
    }
  };

  if (restoreUserPending) {
    return <LoginPrompt
      title={t('Auth.LoginInProgress')}
      description={t('Auth.DataLoading')}
      onButtonClicked={doLogin}
      error={loginError}/>;
  }

  if (props.authService.isLoggedIn()) {
    return null;
  }

  return <LoginPrompt
    title={t('Auth.RedirectionInProgress')}
    description={t('Auth.LoginNecessary')}
    onButtonClicked={doLogin}
    error={loginError}/>;
}

function LoginPrompt(props: {title: string; description: string; onButtonClicked: () => void; error: Error | null}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="login-prompt-view">
      <BackToHomepage />
      <div className="login-prompt-view__content">
        <div className="box">
          <div className="box__title">{props.title}</div>
          <div className="box__body">
            <p>
              {props.description}
            </p>
            {props.error ? <ErrorRenderer error={props.error} /> : ''}
          </div>
          <div className="box__footer">
            {t('Auth.ProblemsWithLogin')}
            <button className="btn btn--default" onClick={props.onButtonClicked}>{t('Auth.LoginNow')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
