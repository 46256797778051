import { TextHighlight } from '../TextHighlight';

type ProcessModelProps = {
  id: string;
  title: string;
  body?: string;
  startButtonTitles: {[startEventId: string]: string};
  onStart: (processId: string, startEventId?: string) => void;
  searchFilter?: string;
}

export function ProcessModel(props: ProcessModelProps): JSX.Element {
  const {
    id,
    title,
    body,
    startButtonTitles,
    onStart,
    searchFilter,
  } = props;

  const safeId = id.trim().replaceAll(' ', '-');

  const searchWords = searchFilter?.trim().split(/\s+/);

  return (
    <div className={`startable startable--process-model process-model process-model--${safeId}`}>
      <div className="startable__title"><TextHighlight text={title} highlight={searchWords} /></div>
      <div className="startable__body"><TextHighlight text={body} highlight={searchWords} /></div>
      <div className='startable__buttons'>
        {
          Object.keys(startButtonTitles).map((startEventId) => (
            <button
              className={`btn btn-sm btn--start-button process-model__start-button process-model__start-button--${startEventId}`}
              key={`${id}-${startEventId}`}
              onClick={(): void => onStart(id, startEventId)}>
              <TextHighlight text={startButtonTitles[startEventId]} highlight={searchWords} />
            </button>
          ))
        }
      </div>
    </div>
  );
}
