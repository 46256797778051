import { AppHeader } from './components/AppHeader';
import { AppSidebar } from './components/AppSidebar';

type LayoutProps = any;

type LayoutSidebarProps = {
  activeNav?: string;
  visible: boolean;
  hideSidebar: () => void;
};

type LayoutHeaderProps = LayoutHeaderPropsWithSearch | LayoutHeaderPropsWithoutSearch;

type LayoutHeaderPropsWithSearch = {
  title?: string;
  onMenuClick: () => void;
  showSearch: true;
  onSearchChanged: (value: string) => void;
};

type LayoutHeaderPropsWithoutSearch = {
  title?: string;
  onMenuClick: () => void;
  showSearch: false;
};

type LayoutContentProps = any;

export function Layout(props: LayoutProps): JSX.Element {
  return <div className="app-layout">
    {props.children}
  </div>;
}

export function LayoutSidebar(props: LayoutSidebarProps): JSX.Element {
  return <div className="app-layout__app-sidebar">
    <AppSidebar
      activeNav={props.activeNav}
      visible={props.visible}
      hideSidebar={props.hideSidebar}
    />
  </div>;

}

export function LayoutHeader(props: LayoutHeaderProps): JSX.Element {
  return <div className="app-layout__app-header">
    <AppHeader
      onMenuClick={props.onMenuClick}
      onSearchChanged={props.showSearch ? props.onSearchChanged : undefined}
      showSearch={props.showSearch}
      title={props.title}
    />
  </div>;
}

export function LayoutContent(props: LayoutContentProps): JSX.Element {
  return <div className="app-layout__content">
    {props.children}
  </div>;
}
