/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import '../style.scss';

import { ICustomFormStatePersistenceService } from '@atlas-engine-contrib/atlas-ui_contracts';
import { registerWebComponents } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';

import {
  AtlasEngineContext,
  AtlasEngineService,
  AuthService,
  AuthServiceDummy,
  ComponentStatePersistenceService,
  IAuthService,
  PortalConfiguration,
  isVersionGreaterEquals,
  parseVersion,
} from '../lib/index';

import { PrivateRoute } from './PrivateRoute';

import { CorrelationTrackerViewWithRouter } from './views/correlation-tracker-view/CorrelationTrackerViewWithRouter';
import { HomepageWithRouter } from './views/homepage-view/HomepageView';
import { GenericViewProps } from './GenericViewProps';
import { StartableListViewWithRouter } from './views/startable-list-view/StartableListView';
import { StartDialogViewWithRouter } from './views/start-dialog-view/StartDialogView';
import { StartProcessViaUrlView } from './views/start-process-via-url-view/StartProcessViaUrlView';
import { TaskListViewWithRouter } from './views/task-list-view/TaskListView';
import { TaskViewWithRouter } from './views/task-view/TaskViewWithRouter';
import { UserSettingsViewWithRouter } from './views/user-settings-view/UserSettingsView';
import { FlashMessage } from './components/FlashMessage';

import { IdentityProvider, useIdentity } from './context';

registerWebComponents();

export type RouteEntry = {
  path: string;
  activeNav?: string;
  exact?: boolean;
  loginRequired: boolean;
  header?: {
    title: string;
    showSearch: boolean;
  };
  main: (props: GenericViewProps) => JSX.Element;
}

type AuthSigninCallbackProps = {
  authService: IAuthService;
}

type AuthSignoutCallbackProps = {
  authService: IAuthService;
}

type AppProps = PortalConfiguration & WithTranslation;

type AppState = {
  sidebarVisible: boolean;
  atlasEngineVersion?: string;
}

class App extends Component<AppProps, AppState> {

  private readonly authService: IAuthService;
  private readonly componentStatePersistenceService: ICustomFormStatePersistenceService
  private readonly atlasEngineService: AtlasEngineService;
  private readonly routes: Array<RouteEntry>;
  private readonly minRequiredAtlasEngineVersion = '11.0.0';

  constructor(props: AppProps) {
    super(props);

    this.state = { sidebarVisible: false };

    this.authService = this.props.useAuthority
      ? new AuthService(this.props.authorityConfiguration)
      : new AuthServiceDummy();

    this.componentStatePersistenceService = new ComponentStatePersistenceService(this.authService);
    this.atlasEngineService = new AtlasEngineService(this.authService, this.props);

    this.routes = [
      {
        path: '/',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <HomepageWithRouter
            {...genericViewProps}
            atlasEngineService={this.atlasEngineService}
            authService={this.authService}
            startDialogsConfig={this.props.startDialogs}
            startablesOrder={this.props.startablesOrder}
            startableGroups={this.props.startableGroups}
            sidebarVisible={this.state.sidebarVisible}
            hideSidebar={(): void => this.hideSidebar()}
          />
        ),
      },
      {
        path: '/startable-list',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartableListViewWithRouter
            {...genericViewProps}
            atlasEngineService={this.atlasEngineService}
            authService={this.authService}
            startDialogsConfig={this.props.startDialogs}
            startablesOrder={this.props.startablesOrder}
            startableGroups={this.props.startableGroups}
          />
        ),
      },
      {
        path: '/task-list',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <TaskListViewWithRouter
            {...genericViewProps}
            atlasEngineService={this.atlasEngineService}
            startDialogsConfig={this.props.startDialogs}
          />
        ),
      },
      {
        path: '/task/:correlationId/:processInstanceId/:flowNodeInstanceId',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <TaskViewWithRouter
            {...genericViewProps}
            componentStatePersistenceService={this.componentStatePersistenceService}
            authService={this.authService}
            taskViewConfig={this.props.taskViewConfig}
          />
        ),
      },
      {
        path: '/correlation/:correlationId/',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <CorrelationTrackerViewWithRouter
            {...genericViewProps}
          />
        ),
      },
      {
        path: '/startdialog/:startDialogId/',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartDialogViewWithRouter
            {...genericViewProps}
            config={this.props.startDialogs}
            authService={this.authService}
          />
        ),
      },
      {
        path: '/start/:processModelId/:payload?',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartProcessViaUrlView atlasEngineService={this.atlasEngineService} />
        ),
      },
      {
        path: '/user-settings',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <UserSettingsViewWithRouter
            {...genericViewProps}
            authService={this.authService}
          />
        ),
      },
      {
        path: '/signin-oidc',
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <AuthSigninCallback
            authService={this.authService}
          />
        ),
      },
      {
        path: '/signout-oidc',
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <AuthSignoutCallback
            authService={this.authService}
          />
        ),
      },

    ];
  }

  public async componentDidMount(): Promise<void> {
    const atlasEngineVersion = await this.atlasEngineService.getAtlasEngineVersion();
    this.setState({ atlasEngineVersion: atlasEngineVersion });
  }

  private toggleSidebar(): void {
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  }

  private hideSidebar(): void {
    this.setState({ sidebarVisible: false });
  }

  public render(): JSX.Element {
    const { t } = this.props;

    const genericViewProps: GenericViewProps = {
      sidebarVisible: this.state.sidebarVisible,
      hideSidebar: () => this.hideSidebar(),
      onMenuClick: () => this.toggleSidebar(),
    };

    const atlasEngineVersionIncompatibleMessage = this.isAtlasEngineVersionCompatible() === false
      ? <FlashMessage className="app-sticky-message" variant="warning" text={t('AtlasEngineOutdated', { version: this.minRequiredAtlasEngineVersion })} />
      : null;

    return (
      <BrowserRouter>
        <IdentityProvider authService={this.authService}>
          <AtlasEngineContext.Provider value={this.atlasEngineService}>
            {atlasEngineVersionIncompatibleMessage}
            <Switch>
              {this.routes
                .map((route, index) => {

                  if (route.loginRequired) {
                    return (
                      <PrivateRoute
                        authService={this.authService}
                        key={index}
                        path={route.path}
                        exact={route.exact}
                      >
                        <route.main {...genericViewProps} />
                      </PrivateRoute>
                    );
                  }

                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={<route.main {...genericViewProps} />}
                    />
                  );
                })}
            </Switch>
          </AtlasEngineContext.Provider>
        </IdentityProvider>
      </BrowserRouter>
    );
  }

  private isAtlasEngineVersionCompatible(): boolean | null {
    if (!this.state.atlasEngineVersion) {
      return null;
    }

    const parsedAtlasEngineVersion = parseVersion(this.state.atlasEngineVersion);
    if (!parsedAtlasEngineVersion) {
      console.warn('Could not parse 5Minds Engine version.');
      return null;
    }

    const parsedMinRequiredAtlasEngineVersion = parseVersion(this.minRequiredAtlasEngineVersion);
    if (!parsedMinRequiredAtlasEngineVersion) {
      console.warn('Could not parse minimum required 5Minds Engine version.');
      return null;
    }

    return isVersionGreaterEquals(parsedAtlasEngineVersion, parsedMinRequiredAtlasEngineVersion);
  }

}

export const TranslatedApp = withTranslation()(App);

/**
 * A callback function to call after a Login was performed.
 *
 * @param props The props used for rendering the dialog that is called after login.
 */
function AuthSigninCallback(props: AuthSigninCallbackProps): null {
  const { authService } = props;
  const history = useHistory();
  const { setIdentity } = useIdentity();

  useEffect(() => {
    authService.processSigninResponse()
      .then((result) => {
        setIdentity(result.identity);
        history.replace(result.targetRoute as any);
      });
  }, [authService]);

  return null;
}

function AuthSignoutCallback(props: AuthSignoutCallbackProps): null {
  const { authService } = props;
  const history = useHistory();
  const { setIdentity } = useIdentity();

  useEffect(() => {
    authService.processSignoutResponse()
      .then(() => {
        setIdentity(null);
        history.replace('/');
      });
  }, [authService]);

  return null;
}
