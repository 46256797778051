import { CustomFormResolver } from '@atlas-engine-contrib/atlas-ui_contracts';

import { IAuthService } from './IAuthService';

export type PortalConfiguration = {
  applicationBaseUrl: string;
  engineUrl: string;
  useAuthority: boolean;
  authorityConfiguration: AuthorityConfig;
  customForms: CustomFormsConfig;
  processModels: ProcessModelsConfig;
  startDialogs: StartDialogsConfig;
  taskViewConfig: TaskViewConfig;
  assets?: string;
  theme?: string;
  translationFolder?: string;
  startablesOrder?: Array<string>;
  startableGroups?: Array<StartableGroupConfig>;
};

export type AuthorityConfig = {
  redirectBasePath: string;
  authority: string;
  clientId: string;
  scopes: string;
}

export type CustomFormsConfig = {[customFormId: string]: CustomFormUrl | CustomFormConfig};
export type CustomFormUrl = string;
export type CustomFormConfig = {
  url: string;
  mode: string;
};

export type ProcessModelsConfig = {
  include?: Array<string>;
  exclude?: Array<string>;
  settings?: ProcessModelSettings;
};
export type ProcessModelSettings = {[processModelId: string]: ProcessModelConfig};
export type ProcessModelConfig = {
  id: string;
  title: string;
  body: string;
  startButtonTitles: {[startEventId: string]: string};
  groupId?: string;
};

export type StartDialogsConfig = {[startDialogId: string]: StartDialogConfig};
export type StartDialogConfig = {
  url: string;
  title: string;
  body: string;
  startButtonTitle: string;
  requiredClaims?: Array<string>;
  groupId?: string;
};

export type StartableGroupConfig = {
  id: string;
  title: string;
}

export type TaskViewConfig = {
  resolveCustomForm: CustomFormResolver;
}

export async function getAllowedStartDialogs(
  startDialogsConfig: StartDialogsConfig,
  authService: IAuthService,
): Promise<StartDialogsConfig> {
  const allowedStartDialogs: StartDialogsConfig = {};

  for await (const [startDialogId, startDialogConfig] of Object.entries(startDialogsConfig)) {
    const requiredClaims = startDialogConfig.requiredClaims;

    if (!requiredClaims || requiredClaims.length === 0) {
      allowedStartDialogs[startDialogId] = startDialogConfig;
      continue;
    }

    let userHasClaim = false;

    for await (const claim of requiredClaims) {
      if (await authService.hasClaim(claim)) {
        userHasClaim = true;
        break;
      }
    }

    if (userHasClaim) {
      allowedStartDialogs[startDialogId] = startDialogConfig;
    }
  }

  return allowedStartDialogs;
}
