import { StartDialogConfig } from '../../../lib/index';
import { TextHighlight } from '../TextHighlight';

type StartDialogProps = {
  startDialogId: string;
  onStart: (startDialogId: string) => void;
  searchFilter?: string;
} & StartDialogConfig;

export function StartDialog(props: StartDialogProps): JSX.Element {
  const {
    title,
    body,
    startButtonTitle,
    onStart,
    startDialogId,
    searchFilter,
  } = props;

  const safeStartDialogId = startDialogId.trim().replaceAll(' ', '-');

  const searchWords = searchFilter?.trim().split(/\s+/);

  return (
    <div className={`startable startable--start-dialog start-dialog start-dialog--${safeStartDialogId}`}>
      <div className="startable__title"><TextHighlight text={title} highlight={searchWords} /></div>
      <div className="startable__body"><TextHighlight text={body} highlight={searchWords} /></div>
      <div className='startable__buttons'>
        <button className={'btn btn-sm btn--start-button'} onClick={(): void => onStart(startDialogId)}>
          <TextHighlight text={startButtonTitle} highlight={searchWords} />
        </button>
      </div>
    </div>
  );
}
