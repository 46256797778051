/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAuthService, IdentityWithEmail, ProcessSigninResponseResult } from './IAuthService';

const dummyIdentity = {
  userId: 'test.user@email.de',
  email: 'test.user@email.de',
  token: 'ZHVtbXlfdG9rZW4=',
};

export class AuthServiceDummy implements IAuthService {
  public login(targetRoutingState: unknown): Promise<void> {
    return Promise.resolve();
  }

  public logout(): void {

  }

  public processSigninResponse(): Promise<ProcessSigninResponseResult> {
    return Promise.resolve({
      identity: dummyIdentity,
      targetRoute: '/',
    });
  }

  public processSignoutResponse(): Promise<void> {
    return Promise.resolve();
  }

  public detectLoggedInFlag(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public getIdentity(): Promise<IdentityWithEmail> {
    return Promise.resolve(dummyIdentity);
  }

  public isLoggedIn(): boolean {
    return true;
  }

  public hasClaim(claim: string): Promise<boolean> {
    return Promise.resolve(true);
  }
}
