import { DataModels, ServiceUnavaliableError } from '@atlas-engine/atlas_engine_sdk';

export type AnyTaskType = DataModels.FlowNodeInstances.UserTaskInstance | DataModels.FlowNodeInstances.ManualTaskInstance;

export type TaskListWithCorrelation = {
  taskList: Array<AnyTaskType>;
  correlation: DataModels.Correlation.Correlation;
  correlationName?: string;
  correlationDescription?: string;
}

export enum RemoteServiceErrorTypes {
  ECONNREFUSED = 'ECONNREFUSED',
  ECONNRESET = 'ECONNRESET',
  ETIMEDOUT = 'ETIMEDOUT',
}

export function isRemoteConnectionErrorCode(errorCode: string): boolean {
  return errorCode === RemoteServiceErrorTypes.ECONNREFUSED ||
    errorCode === RemoteServiceErrorTypes.ECONNRESET ||
    errorCode === RemoteServiceErrorTypes.ETIMEDOUT;
}

export function isConnectionError(error: any): boolean {
  return isRemoteConnectionErrorCode(error.code) || error.message.startsWith('NetworkError') || error.message === 'Failed to fetch';
}

export class AtlasAuthorityUnreachableError extends ServiceUnavaliableError {
  public readonly errorType: RemoteServiceErrorTypes;

  constructor(errorType: RemoteServiceErrorTypes) {
    super('5Minds Authority is unavailable');
    this.errorType = errorType;
  }
}

export class AtlasEngineUnreachableError extends ServiceUnavaliableError {
  public readonly errorType: RemoteServiceErrorTypes;

  constructor(errorType: RemoteServiceErrorTypes) {
    super('5Minds Engine is unavailable');
    this.errorType = errorType;
  }
}
