import { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { DataModels, Subscription } from '@atlas-engine/atlas_engine_sdk';

import { AnyTaskType, AtlasEngineService } from '../../../lib/index';

import { CorrelationFlashMessage } from './CorrelationFlashMessage';
import { Task } from './Task';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { BackToHomepage } from '../../components/BackToHomepage';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

type CorrelationTrackerViewState = {
  tasks: Array<AnyTaskType>;
  correlation: DataModels.Correlation.Correlation | null;
  correlationName: string | null;
  isLoading: boolean;
  loadingError: Error | null;
};

type CorrelationTrackerViewProps = {
  atlasEngineService: AtlasEngineService;
  correlationId: string;
  autoNavigate: boolean;
} & WithTranslation & GenericViewProps;

class CorrelationTrackerView extends Component<CorrelationTrackerViewProps, CorrelationTrackerViewState> {

  public state: CorrelationTrackerViewState = {
    tasks: [],
    correlation: null,
    correlationName: null,
    isLoading: true,
    loadingError: null,
  };

  private correlationChangedSubscriptions: Array<Subscription> = [];
  private correlationProgressSubscriptions: Array<Subscription> = [];

  public async componentDidMount(): Promise<void> {
    this.loadData();

    this.correlationProgressSubscriptions = await this.props
      .atlasEngineService
      .onCorrelationProgress(
        this.props.correlationId,
        async () => {
          await this.loadCorrelation();
          await this.loadTasksForCorrelation();
        },
      );

    this.correlationChangedSubscriptions = await this.props
      .atlasEngineService
      .onCorrelationStateChanged(this.props.correlationId, this.handleCorrelationUpdated);
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.atlasEngineService.removeSubscriptions(this.correlationProgressSubscriptions);
    await this.props.atlasEngineService.removeSubscriptions(this.correlationChangedSubscriptions);
  }

  public render(): JSX.Element {
    if (this.state.loadingError) {
      return <ErrorRenderer error={this.state.loadingError} />;
    }

    if (this.state.isLoading || !this.state.correlation) {
      return <Alert variant='info'>{this.props.t('CorrelationTracker.DataLoading')}</Alert>;
    }

    const autoNavigateToOnlyTask = this.state.tasks.length === 1 && this.props.autoNavigate;
    if (autoNavigateToOnlyTask) {
      const task = this.state.tasks[0];
      return <Redirect to={`/task/${task.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`} />;
    }

    const correlationIsFinished = this
      .state
      .correlation
      .processInstances
      ?.every((e) => e.state === DataModels.ProcessInstances.ProcessInstanceState.finished) ?? true;

    if (correlationIsFinished) {
      const lastActiveCorrelationQueryParam = `lastActiveCorrelation=${this.state.correlation.correlationId}`;
      const returnToStartDialog = this.state.correlation.metadata?.returnToStartDialog;

      if (returnToStartDialog) {
        return <Redirect to={`/startdialog/${returnToStartDialog}?${lastActiveCorrelationQueryParam}`} />;
      }

      return <Redirect to={`?${lastActiveCorrelationQueryParam}`} />;
    }

    return (
      <Layout>
        <LayoutSidebar visible={this.props.sidebarVisible} hideSidebar={this.props.hideSidebar} />
        <LayoutContent>

          <div className="correlation-tracker-view">
            <BackToHomepage />
            <div className="correlation-tracker-view__infopoint">
              <CorrelationFlashMessage correlation={this.state.correlation} tasks={this.state.tasks}/>
            </div>
            <div className="correlation-tracker-view__content">

              <div className="box">
                <div className="box__title">
                  {this.props.t(
                    'CorrelationTracker.TasksForCorrelationName',
                    { correlationName: this.state.correlationName ?? this.props.t('CorrelationTracker.CorrelationNameUnknown') },
                  )}
                </div>
                <div className="box__subtitle box__subtitle--no-padding box__subtitle--task-list">
                  <div className="task-list-header">
                    <div className="task-list-header__title">
                      {this.props.t('CorrelationTracker.HeaderProcessModel')}
                    </div>
                    <div className="task-list-header__task">
                      {this.props.t('CorrelationTracker.HeaderTasks')}
                    </div>
                  </div>
                </div>
                <div className="box__body box__body--task-list">
                  {this.state.tasks.map((task) => {
                    const processInstancePath = this.getProcessInstancePathStringForTask(task, this.state.correlation);

                    return (
                      <Task
                        task={task}
                        key={task.flowNodeInstanceId}
                        processInstancePath={processInstancePath ?? this.props.t('CorrelationTracker.ProcessInstancePathUnknown')}
                      />
                    );
                  })}
                </div>
              </div>

            </div>
          </div>

        </LayoutContent>
      </Layout>
    );
  }

  private async loadData(): Promise<void> {
    try {
      await Promise.all([
        this.loadCorrelation(),
        this.loadTasksForCorrelation(),
      ]);

      this.setState({ loadingError: null });
    } catch (error) {
      this.setState({ loadingError: error });
    }

    this.setState({ isLoading: false });
  }

  private async loadCorrelation(): Promise<void> {
    const correlation = await this.props.atlasEngineService.getCorrelation(this.props.correlationId);
    this.handleCorrelationUpdated(correlation);
  }

  private async loadTasksForCorrelation(): Promise<void> {
    const tasks = await this.props.atlasEngineService.getTasksInCorrelation(this.props.correlationId);
    this.setState({ tasks: tasks });
  }

  private handleCorrelationUpdated = (correlation: DataModels.Correlation.Correlation): void => {
    this.setState({
      correlation: correlation,
      correlationName: correlation
        .processInstances
        ?.find((instance) => !instance.parentProcessInstanceId)?.processModelName ?? this.props.t('CorrelationTracker.ProcessDefinitionNameNotSet'),
    });
  }

  private getProcessInstancePathStringForTask = (task: AnyTaskType, correlation: DataModels.Correlation.Correlation | null): string | null => {
    if (!task.processInstanceId || !correlation) {
      return null;
    }

    return this.getProcessInstancePath(task.processInstanceId, correlation)
      .map((processInstance) => processInstance.processModelName)
      .join(' / ');
  }

  /**
   * Returns a sorted list of process instances that take up the execution path in a correlation.
   *
   * For example:
   *  - 'Foo' starts 'Bar' and 'Baz (1)'
   *  - 'Bar' starts 'Quz'
   *  - 'Quz' starts 'Baz (2)'
   *
   *  The path for 'Baz (2)' is 'Foo' > 'Bar' > 'Quz' > 'Baz (2)'.
   *
   * The last element of the array is always the searched process instance.
   *
   * @param searchedProcessInstanceId
   * @param correlation
   */
  private getProcessInstancePath(
    searchedProcessInstanceId: string,
    correlation: DataModels.Correlation.Correlation,
  ): Array<DataModels.ProcessInstances.ProcessInstance> {

    const path = [];

    let currentSearchedParent = searchedProcessInstanceId;
    let currentProcess = null;

    do {
      currentProcess = correlation.processInstances?.find((instance) => currentSearchedParent === instance.processInstanceId);
      if (!currentProcess) {
        break;
      }

      currentSearchedParent = currentProcess.parentProcessInstanceId || '';
      path.unshift(currentProcess);

    } while (currentProcess);

    return path;
  }

}

export const TranslatedCorrelationTrackerView = withTranslation()(CorrelationTrackerView);
