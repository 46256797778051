import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';

import { useIdentity } from '../context';

export type AppSidebarProps = {
  activeNav?: string;
  visible: boolean;
  hideSidebar: () => void;
}

export function AppSidebar(props: AppSidebarProps): JSX.Element {
  const { t } = useTranslation();
  const { identity } = useIdentity();

  const homepageIcon = <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clipRule="evenodd" /></svg>;
  const startableListIcon = <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z" clipRule="evenodd" /><path d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z" /></svg>;
  const taskListIcon = <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" /></svg>;
  const userSettingsIcon = <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" /></svg>;

  const hideSidebar = (): void => {
    // TODO: investigate why this causes a re-render of <App/> unless it's defered
    setTimeout(() => props.hideSidebar(), 50);
  };

  return (
    <Fragment>
      <div className={`app-sidebar__backdrop-on-mobile app-sidebar__backdrop-on-mobile--${props.visible ? 'visible' : 'hidden'}`}>
        <div className="backdrop-on-mobile">
          <div className="backdrop-on-mobile__close-icon" onClick={hideSidebar}>
            <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
          </div>
        </div>
      </div>
      <div className={`app-sidebar app-sidebar--${props.visible ? 'visible' : 'hidden'}`}>
        <div className="app-sidebar__top">
          <LinkContainer to='/' onClick={hideSidebar}>
            <div className="app-sidebar__logo">
              <img src="/assets/logo.png" alt={t('Sidebar.Title')} />
            </div>
          </LinkContainer>
          <div className="app-sidebar__main-nav">
            <MainNavNavItem
              icon={homepageIcon}
              label={t('Sidebar.LinkHomepage')}
              isActive={props.activeNav === 'homepage'}
              href="/"
              onClick={hideSidebar}
            />
            <MainNavNavItem
              icon={startableListIcon}
              label={t('Sidebar.LinkStartableList')}
              isActive={props.activeNav === 'startable-list'}
              href="/startable-list"
              onClick={hideSidebar}
            />
            <MainNavNavItem
              icon={taskListIcon}
              label={t('Sidebar.LinkTaskList')}
              isActive={props.activeNav === 'task-list'}
              href="/task-list"
              onClick={hideSidebar}
            />
          </div>
        </div>

        {identity == null ? null :
          <MainNavNavItem
            icon={userSettingsIcon}
            label={t('Sidebar.Welcome', { userName: identity.email ?? identity.userId })}
            isActive={props.activeNav === 'user-settings'}
            href="/user-settings"
            onClick={hideSidebar}
          />}
      </div>
    </Fragment>
  );
}

function MainNavNavItem(props: any): JSX.Element {
  let className = 'main-nav__nav-item';
  if (props.isActive) {
    className += ' main-nav__nav-item--active';
  }

  return <LinkContainer to={props.href} onClick={props.onClick}>
    <div className={className}>
      <div className="nav-item">
        {props.icon} {props.label}
      </div>
    </div>
  </LinkContainer>;
}
