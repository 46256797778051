import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Base64 } from 'js-base64';

import { AtlasEngineService } from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';

export type StartProcessViaUrlViewProps = {
  atlasEngineService: AtlasEngineService;
};

type StartProcessViaUrlParameters = {
  processModelId: string;
  payload: any;
}

export function StartProcessViaUrlView(props: StartProcessViaUrlViewProps): JSX.Element {
  const { t } = useTranslation();

  const {
    processModelId,
    payload: encodedPayload,
  } = useParams<StartProcessViaUrlParameters>();

  const history = useHistory();
  const [
    lastError,
    setLastError,
  ] = useState<Error | null>(null);

  useEffect(() => {
    if (!processModelId) {
      return;
    }

    let payload;
    if (encodedPayload) {
      try {
        payload = JSON.parse(Base64.decode(encodedPayload));
      } catch (error) {
        setLastError(error);
        return;
      }
    }

    props.atlasEngineService.startProcessInstance(processModelId, payload)
      .then((result) => {
        history.replace(`/correlation/${result.correlationId}`);
      })
      .catch((error) => {
        setLastError(error);
      });

  }, [
    processModelId,
    encodedPayload,
    props.atlasEngineService,
    history,
  ]);

  if (lastError !== null) {
    return <ErrorRenderer error={lastError} />;
  }

  return <span className="start-process-via-url-view">{t('StartProcessViaURL.ProcessIsStarting')}</span>;
}
